<template>
  <footer class="z-10 w-full bg-primary">
    <div
      class="mx-auto flex max-w-app flex-wrap items-start justify-between py-16"
    >
      <div class="mx-auto text-center md:m-0 md:w-full md:text-left">
        <div
          class="hover:prose-a:text-blue3 prose prose-p:m-0 prose-p:font-light prose-p:text-white prose-a:mb-3 prose-a:inline-block prose-a:text-white prose-a:transition-colors prose-strong:mr-1 prose-strong:text-white prose-img:m-0"
        >
          <div>
            <p>
              <a href="/" title="Site Logo"
                ><img src="/logo-white.svg?url" alt="Logo"
              /></a>
            </p>
            <p>{{ t("footer.address.line1") }}</p>
            <p>{{ t("footer.address.line2") }}</p>
            <p>{{ t("footer.address.line3") }}</p>
          </div>
        </div>
      </div>
      <div class="mx-auto mt-12 text-center md:mx-0 md:text-left">
        <p class="mb-2 text-[12px] text-primary-foreground opacity-60">
          {{ t("footer.commercialCustomersNote") }}
        </p>
        <div
          class="relative text-primary-foreground prose-p:text-sm prose-img:mb-0 prose-img:mr-2 prose-img:inline-block"
        >
          <p>{{ t("footer.copyright") }}</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts" setup>
// --- external
import { useI18n } from "vue-i18n";

// -----------------------------------------------------------------------------

const { t } = useI18n();
</script>
