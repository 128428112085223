<template>
  <div
    class="flex w-full items-center justify-between space-x-2 md:justify-end md:space-x-6"
  >
    <a class="relative z-20 md:hidden" href="/">
      <picture class="h-full w-full">
        <source srcset="/logo-white.svg" type="image/svg" />
        <img class="m-0 h-5" src="/logo.svg?url" alt="logo" />
        <span class="sr-only">
          {{ t("header.title") }}
        </span>
      </picture>
    </a>

    <div
      class="flex items-center justify-end space-x-4 md:space-x-8"
      v-if="meta.isAvailable"
    >
      <UpmSessionLoginPopover v-if="!meta.isAuthenticated">
        <span class="flex items-center space-x-3 text-sm text-primary">
          <span class="hidden pr-1 leading-none sm:block">
            {{ t("header.title") }}
            <strong>{{ t("header.login") }}</strong>
          </span>
        </span>
      </UpmSessionLoginPopover>

      <UpmCurrency />

      <UpmSessionLoginPopover v-if="!meta.isAuthenticated">
        <Avatar icon="account" size="xs" color="primary" fit="contain" />
      </UpmSessionLoginPopover>

      <div v-if="meta.isAuthenticated && user" class="flex items-center">
        <UpmSessionDetailsDropdown>
          <Avatar
            v-bind="user.avatar"
            size="xs"
            color="primary"
            class="cursor-pointer"
            focusable
          />
        </UpmSessionDetailsDropdown>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
// --- external
import { useI18n } from "vue-i18n";

// --- internal
import {
  useSession,
  UpmCurrency,
  UpmSessionLoginPopover,
  UpmSessionDetailsDropdown,
} from "@upmind-automation/client-vue";
import { Avatar } from "@upmind-automation/upmind-ui";

// -----------------------------------------------------------------------------

const { t } = useI18n();
const { meta, user } = useSession();
</script>
