<template>
  <section class="session" :class="styles.session.root">
    <header :class="styles.session.header" v-if="!noHeader || !!$slots.header">
      <slot name="header" v-bind="{ meta, user }">
        <template v-if="!meta.isAuthenticated && meta.showRegisterForm">
          <span :class="styles.session.text">
            {{ t("session.unauthenticated.header.register.text") }}
          </span>

          <h1 :class="styles.session.title">
            {{ t("session.unauthenticated.header.register.title") }}
          </h1>
        </template>

        <template v-if="!meta.isAuthenticated && meta.showLoginForm">
          <span :class="styles.session.text">
            {{ t("session.unauthenticated.header.login.text") }}
          </span>

          <h1 :class="styles.session.title">
            {{ t("session.unauthenticated.header.login.title") }}
          </h1>
        </template>

        <template v-if="meta.isAuthenticated">
          <i18n-t
            :class="styles.session.text"
            keypath="session.authenticated.footer.text"
            tag="p"
          >
            <template #[`action`]>
              <Button
                variant="link"
                @click.prevent="logout"
                :label="t('session.authenticated.footer.action')"
              />
            </template>
          </i18n-t>

          <i18n-t
            :class="styles.session.title"
            keypath="session.authenticated.header.title"
            tag="h3"
          >
            <template #[`name`]>
              <strong :class="styles.session.name">
                {{ user?.display }}
              </strong>
            </template>
          </i18n-t>
        </template>
      </slot>
    </header>

    <Auth
      v-if="!meta.isAuthenticated"
      :class="styles.session.content"
      :block-tabs="blockTabs"
      :stretch-tabs="stretchTabs"
      :no-tabs="noTabs"
      :color="color"
      :model-value="modelValue"
    >
    </Auth>

    <footer :class="styles.session.footer" v-if="!noFooter || !!$slots.footer">
      <slot name="footer" v-bind="{ meta, user }"> </slot>
    </footer>
  </section>
</template>

<script lang="ts" setup>
// --- external
import { useI18n } from "vue-i18n";

// --- internal
import { useSession } from "@upmind-automation/headless-vue";
import { useStyles } from "@upmind-automation/upmind-ui";
import config from "./config.cva";

// --- components
import Auth from "./AuthTabs.vue";

// --- custom elements
import { Button } from "@upmind-automation/upmind-ui";

// --- types
import type { AuthProps } from "./types";

// -----------------------------------------------------------------------------

const props = withDefaults(defineProps<AuthProps>(), {
  modelValue: "register",
});

const { t } = useI18n();
const styles = useStyles(["session"], props, config);
const { meta, user } = useSession();

// ---
</script>
