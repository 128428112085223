import { useRoutingFlows, ROUTE } from "@upmind-automation/client-vue";

export default {
  register: () => {
    // Register the core routing flows, this is where we customize the routing flows
    const { session } = useRoutingFlows();
    session.register();
  },
  routes: [
    {
      path: "/auth",
      name: ROUTE.SESSION,
      component: () => import("./Register.vue"),
    },
    {
      path: "/auth/login",
      name: ROUTE.SESSION_LOGIN,
      component: () => import("./Login.vue"),
    },
    {
      path: "/auth/register",
      name: ROUTE.SESSION_REGISTER,
      alias: ["/auth/signup"],
      component: () => import("./Register.vue"),
    },
    {
      path: "/auth/logout",
      alias: ["/auth/signout"],
      name: ROUTE.SESSION_END,
      component: () => import("./Logout.vue"),
    },
  ],
};
