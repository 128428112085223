// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { cva } from "class-variance-authority";
// -----------------------------------------------------------------------------

export default {
  card: cva("bg-base"),
  section: {
    title: cva("!text-2xl text-primary"),
  },
  product: {
    summary: {
      imageRoute: cva("hidden"),
    },
  },
  badge: cva("", {
    variants: {
      variant: {
        tonal: "border border-transparent",
      },
      color: {
        promotion: "bg-promotion text-promotion-foreground",
      },
    },
    compoundVariants: [
      {
        color: "promotion",
        variant: "tonal",
        class: "bg-promotion text-promotion-foreground",
      },
    ],
  }),
  link: cva("no-underline"),
};
