import type { Config } from "tailwindcss";
import type { PluginUtils } from "tailwindcss/types/config";
// ---
import defaultTheme from "tailwindcss/defaultTheme";

// -----------------------------------------------------------------------------

const colors = {
  transparent: "transparent",
  background: "#f6f9fc",
  foreground: "#000000",

  // --- Theme Variants

  base: {
    DEFAULT: "#FFFFFF",
    50: "#fbfcfd",
    100: "#f9fafb",
    200: "#dde3e8",
    300: "#c7d1da",
    400: "#b0bbc9",
    500: "#919eb2",
    600: "#858fa6",
    700: "#727b90",
    800: "#5d6476",
    900: "#4f5560",
    950: "#2e3138",
    foreground: "#13375a",
    background: "#f6f9fc",
    muted: {
      DEFAULT: "#dde3e8",
      foreground: "#65758b",
      active: "#c7d1da",
    },
  },

  primary: {
    DEFAULT: "#13375a",
    50: "#f1f7fe",
    100: "#e3eefb",
    200: "#c0ddf7",
    300: "#89c2f0",
    400: "#4aa2e6",
    500: "#2387d4",
    600: "#156ab4",
    700: "#125592",
    800: "#134879",
    900: "#13375a",
    950: "#0e2743",
    foreground: "#ffffff",
    background: "#142e4d",
    muted: {
      DEFAULT: "#e3eefb",
      foreground: "#4aa2e6",
      active: "#c0ddf7",
    },
  },

  secondary: {
    DEFAULT: "#27aee4",
    50: "#f1f9fe",
    100: "#e2f3fc",
    200: "#bee6f9",
    300: "#85d3f4",
    400: "#44bcec",
    500: "#27aee4",
    600: "#0e84bb",
    700: "#0d6997",
    800: "#0f5a7d",
    900: "#124a68",
    950: "#0c2f45",
    foreground: "#ffffff",
    background: "#05c3de",
    muted: {
      DEFAULT: "#e2f3fc",
      foreground: "#44bcec",
      active: "#bee6f9",
    },
  },

  accent: {
    DEFAULT: "#d4effa",
    50: "#f6fcfe",
    100: "#f1fafd",
    200: "#eaf7fd",
    300: "#e2f4fc",
    400: "#dbf2fb",
    500: "#d4effa",
    600: "#b1c7d0",
    700: "#8d9fa7",
    800: "#6a787d",
    900: "#475053",
    950: "#2a3032",
    foreground: "#2a3032",
    background: "#d4effa",
    muted: {
      DEFAULT: "#f1fafd",
      foreground: "#dbf2fb",
      active: "#eaf7fd",
    },
  },

  // --- State Variants
  promotion: {
    DEFAULT: "#ED6A5A",
    50: "#fef3f2",
    100: "#fde6e3",
    200: "#fdd0cb",
    300: "#fab0a7",
    400: "#f58274",
    500: "#ed6a5a",
    600: "#d83d2a",
    700: "#b53020",
    800: "#962b1e",
    900: "#7d291f",
    950: "#44110b",
    foreground: "#ffffff",
    background: "#ED6A5A",
    muted: {
      DEFAULT: "#fde6e3",
      foreground: "#f58274",
      active: "#fdd0cb",
    },
  },

  destructive: {
    DEFAULT: "#e11d48",
    50: "#fff1f2",
    100: "#ffe4e6",
    200: "#fecdd3",
    300: "#fda4af",
    400: "#fb7185",
    500: "#f43f5e",
    600: "#e11d48",
    700: "#be123c",
    800: "#9f1239",
    900: "#881337",
    950: "#4c0519",
    foreground: "#ffffff",
    background: "#ff4d6d",
    muted: {
      DEFAULT: "#fff1f2",
      foreground: "#fb7185",
      active: "#fecdd3",
    },
  },

  info: {
    DEFAULT: "#3b82f6",
    50: "#eff5ff",
    100: "#dbe8fe",
    200: "#bfd7fe",
    300: "#93bbfd",
    400: "#609afa",
    500: "#3b82f6",
    600: "#2570eb",
    700: "#1d64d8",
    800: "#1e55af",
    900: "#1e478a",
    950: "#172e54",
    foreground: "#ffffff",
    background: "#3b82f6",
    muted: {
      DEFAULT: "#eff5ff",
      foreground: "#609afa",
      active: "#dbe8fe",
    },
  },

  success: {
    DEFAULT: "#10b981",
    50: "#ecfdf7",
    100: "#d1faec",
    200: "#a7f3da",
    300: "#6ee7bf",
    400: "#34d39e",
    500: "#10b981",
    600: "#059666",
    700: "#047852",
    800: "#065f42",
    900: "#064e36",
    950: "#022c1e",
    foreground: "#ffffff",
    background: "#10b981",
    muted: {
      DEFAULT: "#d1faec",
      foreground: "#6ee7bf",
      active: "#a7f3da",
    },
  },

  warning: {
    DEFAULT: "#fb923c",
    50: "#fff5ed",
    100: "#ffe8d5",
    200: "#fed0aa",
    300: "#fdb274",
    400: "#fb923c",
    500: "#f97c16",
    600: "#ea700c",
    700: "#c25e0c",
    800: "#9a4f12",
    900: "#7c4212",
    950: "#432207",
    foreground: "#fff3e0",
    background: "#fb923c",
    muted: {
      DEFAULT: "#fff5ed",
      foreground: "#fdb274",
      active: "#ffe8d5",
    },
  },

  error: {
    DEFAULT: "#ef4444",
    50: "#fef2f2",
    100: "#fee2e2",
    200: "#fecaca",
    300: "#fca5a5",
    400: "#f87171",
    500: "#ef4444",
    600: "#dc2626",
    700: "#b91c1c",
    800: "#991b1b",
    900: "#7f1d1d",
    950: "#450a0a",
    foreground: "#ffebee",
    background: "#ef4444",
    muted: {
      DEFAULT: "#fef2f2",
      foreground: "#f87171",
      active: "#fee2e2",
    },
  },

  disabled: {
    DEFAULT: "#9e9e9e",
    foreground: "#eeeeee",
  },

  // --- Component Variants

  card: {
    DEFAULT: "#ffffff",
    foreground: "#0f1729",
  },

  popover: {
    DEFAULT: "#ffffff",
    foreground: "#0f1729",
  },

  control: {
    DEFAULT: "#ffffff",
    background: "#ffffff",
    foreground: "#13375a",
    active: {
      DEFAULT: "#13375a",
      muted: "#13375a05",
      foreground: "#ffffff",
      background: "#ffffff",
      hover: "#13375a05",
      focus: "#13375a10",
    },
    error: {
      DEFAULT: "#EF4444",
      muted: "#EF444420",
      foreground: "#ffffff",
      background: "#EF4444",
    },
  },

  icon: {
    primary: "#13375a",
    secondary: "#27aee4",
  },
};

// -----------------------------------------------------------------------------

export default {
  content: [],
  colors,
  fontFamily: {
    sans: ["Inter", ...defaultTheme.fontFamily.sans].toString(),
    serif: ["Inter", ...defaultTheme.fontFamily.serif].toString(),
    mono: ["Inconsolata", ...defaultTheme.fontFamily.mono].toString(),
  },
  fontSize: {
    "2xs": ".75rem", // 12px
    xs: ".875rem", // 14px
    sm: ".9375rem", // 15px
    md: "1rem", // 16px
    lg: "1.125rem", // 18px
    xl: "1.25rem", // 20px
    "2xl": "1.5rem", // 24px
    "3xl": "1.875rem", // 30px
    "4xl": "2.25rem", // 36px
    "5xl": "3rem", // 48px
  },
  width: {
    "dropdown-3xs": "6rem",
    "dropdown-2xs": "8rem",
    "dropdown-xs": "10rem",
    "dropdown-sm": "12rem",
    "dropdown-md": "14rem",
    "dropdown-lg": "16rem",
    "dropdown-xl": "18rem",
    "dropdown-2xl": "20rem",
  },
  maxWidth: {
    app: "clamp(320px, 90%, 1280px)",
  },
  leading: {
    none: 1,
    tight: 1.25,
    normal: 1.5,
    loose: 2,
  },
  tracking: {
    tight: "-0.05em",
    normal: "0",
    wide: "0.05em",
  },
  borderColor: {
    DEFAULT: colors.base[300],
    input: colors.base[200],
    control: colors.base[200],
  },
  borderRadius: {
    DEFAULT: ".5rem",
    none: "0",
    xs: "0", // 1px
    sm: "0", // 2px
    md: ".0", // 4px
    lg: ".0", // 8px
    xl: "0", // 16px
    "2xl": "0", // 24px
    "3xl": "0", // 32px
    full: "9999px", // 9999px,
    pill: "0", // 9999px,
    button: ".25rem", // 4px,
    box: ".5rem", // 8px,
  },
  shadows: {
    default: "0 2px 4px 0 rgba(0,0,0,0.10)",
    md: "0 4px 8px 0 rgba(0,0,0,0.12), 0 2px 4px 0 rgba(0,0,0,0.08)",
    lg: "0 15px 30px 0 rgba(0,0,0,0.11), 0 5px 15px 0 rgba(0,0,0,0.08)",
    inner: "inset 0 2px 4px 0 rgba(0,0,0,0.06)",
    outline: "0 0 0 3px rgba(52,144,220,0.5)",
    none: "none",
  },
  ringColor: {
    DEFAULT: colors.control.active.muted,
    ring: colors.control.active.muted,
    invalid: colors.control.error.muted,
  },
  typography: ({ theme }: PluginUtils) => ({
    DEFAULT: {
      css: {
        fontFamily: theme("fontFamily.body"),

        h1: {
          fontFamily: theme("fontFamily.display"),
        },
        h2: {
          fontFamily: theme("fontFamily.display"),
        },
        h3: {
          fontFamily: theme("fontFamily.display"),
        },
        h4: {
          fontFamily: theme("fontFamily.display"),
        },
        h5: {
          fontFamily: theme("fontFamily.display"),
        },
        h6: {
          fontFamily: theme("fontFamily.display"),
        },
      },
    },
  }),
  textColor: {
    emphasis: {
      disabled: "color-mix(in srgb, currentColor 38%, transparent)",
      medium: "color-mix(in srgb, currentColor 65%, transparent)",
      high: "color-mix(in srgb, currentColor 87%, transparent)",
      none: "currentColor",
    },
  },
} satisfies Config;
