// --- external

// --- internal
import { useRoutingFlows } from "@upmind-automation/client-vue";

// --- utils

// --- types
import { ROUTE, type Route } from "@upmind-automation/client-vue";

export default {
  register: (_route: Route) => {
    // Register the core routing flows, this is where we customize the routing flows
    const { basket } = useRoutingFlows();
    basket.register();
  },

  routes: [
    {
      path: "/cart",
      name: ROUTE.BASKET,
      alias: ["/basket"],
      component: () => import("./Basket.vue"),
    },
  ],
};
