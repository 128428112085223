import { ROUTE } from "@upmind-automation/client-vue";

export default {
  register: () => {},

  routes: [
    {
      path: "/error",
      name: ROUTE.ERROR,
      component: () => import("./Error.vue"),
    },
    {
      path: "/empty",
      name: ROUTE.EMPTY,
      component: () => import("./Empty.vue"),
    },
  ],
};
