<template>
  <Upm :theme="theme">
    <template #header>
      <Header />
    </template>

    <template #footer>
      <Footer />
    </template>
  </Upm>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { Upm } from "@upmind-automation/client-vue";
import Header from "./components/header/Header.vue";
import Footer from "./components/footer/Footer.vue";
import theme from "./assets/theme";

const { t } = useI18n();
</script>
